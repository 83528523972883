<template>
  <div class="h-full flex flex-col">
    <fw-panel :title="$t('justification')" boxed="sm" class="m-5" custom-class="bg-white flex flex-col gap-3">
      <div>
        <fw-label>{{ $t('absenceComunication.label') }}:</fw-label>
        <OptionsCard
          v-if="justification.status !== 'manager_reviewed' || changeDecision"
          :options="comunicationTypes"
          :selected="absenceComunication.type"
          @selected="selectAbsenceComunication"
        ></OptionsCard>
        <div v-else>
          {{ $t(`absenceComunication.${absenceComunication.type}`) }}
        </div>
        <fw-tip v-if="$v.absenceComunication.type.$error" error>
          {{ $t('errors.required.type') }}
        </fw-tip>
      </div>

      <div v-if="absenceComunication.type == 'not_predictable'">
        <fw-label>{{ $t('comunicationDate') }}</fw-label>
        <b-datepicker
          v-if="justification.status !== 'manager_reviewed' || changeDecision"
          v-model="$v.absenceComunication.date.$model"
          :disabled="!canEdit"
          :max-date="maxDate"
          locale="pt-PT"
          icon="calendar-alt"
          trap-focus
          :placeholder="$t('comunicationDate')"
          append-to-body
        >
        </b-datepicker>
        <div v-else>
          {{ absenceComunication.date | formatDate }}
        </div>

        <fw-tip v-if="$v.absenceComunication.date.$error" error>
          <span v-if="!$v.absenceComunication.date.required">{{ $t('errors.required.date') }}</span>
          <span v-else>{{ $t('errors.invalid.date') }}</span>
        </fw-tip>
      </div>

      <div v-if="showComment">
        <div>
          <fw-label>{{ $t('message') }}</fw-label>
          <b-input v-model="$v.comment.$model" type="textarea" :placeholder="$t('writeMessage')" />
          <fw-tip v-if="$v.comment.$error" error>
            <span v-if="!$v.comment.required">{{ $t('errors.required.comment') }}</span>
            <span v-else>{{ $t('errors.invalid.comment') }}</span>
          </fw-tip>
        </div>
        <fw-panel-info clean class="mt-5">{{ $t('toCompleteInfo') }}</fw-panel-info>
        <fw-tip marginless>{{ $t('applWillSendNotification') }}</fw-tip>
      </div>

      <div v-if="justification.status == 'reviewed' && !changeDecision" class="flex items-center justify-end p-5 gap-5">
        <fw-button type="link-muted" @click.native="$emit('close')">
          {{ $t('cancel') }}
        </fw-button>

        <fw-button type="black" @click.native="setManagerDecision(true)">
          {{ $t('changeDecision') }}
        </fw-button>

        <fw-button type="primary" @click.native="setManagerDecision(false)">
          {{ $t('confirm') }}
        </fw-button>
      </div>

      <div v-else class="flex items-center justify-end p-5 gap-5">
        <fw-button type="link-muted" @click.native="$emit('close')">
          {{ $t('cancel') }}
        </fw-button>

        <fw-button v-if="!showComment" type="light-danger" @click.native="showComment = true">
          {{ $t('reject') }}
        </fw-button>
        <fw-button v-if="!showComment" type="primary" @click.native="approveJustification">
          {{ $t('approve') }}
        </fw-button>

        <fw-button v-if="showComment" type="black" @click.native="rejectJustification">
          {{ $t('confirm') }}
        </fw-button>
      </div>
    </fw-panel>
  </div>
</template>

<script>
import OptionsCard from '@/fw-modules/fw-core-vue/ui/components/form/OptionsCard'
import { required, requiredIf, maxValue, minLength, maxLength } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
export default {
  components: {
    OptionsCard
  },

  props: {
    canEdit: {
      type: Boolean,
      default: true
    },
    justification: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      maxDate: new Date(),
      absenceComunication: {
        type: null,
        date: null
      },
      comment: null,
      showComment: false,
      changeDecision: false,
      comunicationTypes: [
        {
          value: 'in_advance',
          text: {
            pt: 'Com a antecedência mínima de 5 dias',
            en: 'At least 5 days in advance'
          }
        },
        {
          value: 'not_predictable',
          text: {
            pt: 'Não era(m) previsível(eis)',
            en: 'Was (were) not foreseeable'
          }
        }
      ]
    }
  },
  validations() {
    return {
      absenceComunication: {
        type: {
          required
        },
        date: {
          required: requiredIf(nestedModel => {
            return nestedModel.type && nestedModel.type == 'not_predictable'
          }),
          max: maxValue(this.maxDate)
        }
      },
      comment: {
        required: requiredIf(() => this.showComment),
        min: minLength(2),
        max: maxLength(65000)
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },

  mounted() {
    this.absenceComunication = {
      type: this.justification.absence_communication,
      date: this.justification.absence_communication_date
        ? Dates.buildCore(this.justification.absence_communication_date).toDate()
        : null
    }
  },

  methods: {
    close() {
      this.changeDecision = false
      this.$emit('close')
    },

    selectAbsenceComunication(option) {
      if (!this.absenceComunication.type) this.$v.$reset()
      this.$set(this.absenceComunication, 'type', option)
    },

    getStatus() {
      return this.justification.manager == this.user.key ? 'manager_reviewed' : 'reviewed'
    },

    setManagerDecision(changeDecision = false) {
      if (changeDecision) {
        this.changeDecision = true
      } else {
        this.$v.$touch()
        if (this.$v.$invalid) return
        this.$emit('approve', Object.assign({ status: 'manager_reviewed' }))
        this.$v.$reset()
        this.close()
      }
    },

    approveJustification() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit(
        'approve',
        Object.assign({ status: this.getStatus(), action: 'approve', comment: this.comment }, this.absenceComunication)
      )
      this.$v.$reset()
      this.close()
    },

    rejectJustification() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit(
        'reject',
        Object.assign({ status: this.getStatus(), action: 'deny', comment: this.comment }, this.absenceComunication)
      )
      this.$v.$reset()
      this.close()
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "justification": "Justificação",
    "comunicationDate": "Data de comunicação",
    "approve": "Aprovar",
    "approved": "Aprovado",
    "reject": "Rejeitar",
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "required": "Obrigatório",
    "writeMessage": "Escreva o(s) motivo(s) que leva a(s) falta(s) a serem considerada(s) injustificadas",
    "toCompleteInfo": "Para finalizar a justificação, carregue em Confirmar.",
    "applWillSendNotification": "A plataforma irá enviar uma notificação de aviso ao .",
    "message": "Mensagem",
    "absenceComunication": {
      "label": "A(s) falta(s) foi(ram) comunicada(s)",
      "in_advance": "Com a antecedência mínima de 5 dias",
      "not_predictable": "Não era(m) previsível(eis)"
    },
    "errors": {
      "required": {
        "type": "Escolha o tipo de comunicação da(s) falta(s)",
        "date": "Insira a data da comunicação",
        "comment": "Insira o(s) motivo(s)"
      },
      "invalid": {
        "date": "Data da comunicação inválida",
        "comment": "Mensagem inválida"
      }
    }
  },
  "en": {
    "justification": "Justification",
    "comunicationDate": "Communication date",
    "approve": "Approve",
    "approved": "Approved",
    "reject": "Reject",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "message": "Message",
    "writeMessage": "Write the reason(s) why the absence(s) is (are) considered unjustified",
    "toCompleteInfo": "To finish the justification, press Confirm.",
    "applWillSendNotification": "The platform will send a notification to .",
    "required": "Required",
    "absenceComunication": {
      "label": "The absence(s) was (were) reported",
      "in_advance": "At least 5 days in advance",
      "not_predictable": "Was (were) not foreseeable"
    },
    "errors": {
      "required": {
        "type": "Choose the type of fault(s) communication",
        "date": "Enter the communication date",
        "comment": "Enter reason(s)"
      },
      "invalid": {
        "date": "Invalid communication date",
        "comment": "Invalid message"
      }
    }
  }
}
</i18n>

<style>
.dropdown-menu {
  z-index: 100 !important;
}
</style>
