var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-layout',{attrs:{"loading":_vm.loading,"management":""},scopedSlots:_vm._u([(_vm.justification.status)?{key:"header-nav",fn:function(){return [_c('fw-tag',{attrs:{"size":"sm","type":_vm.statusTagType,"custom-class":"px-3"}},[(!['draft', 'closed'].includes(_vm.justification.status))?_c('span',{staticClass:"font-regular opacity-70 mr-1"},[_vm._v(_vm._s(_vm.$t('status.label')))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.justification.status}`))+" ")])]},proxy:true}:null,{key:"header-toolbar",fn:function(){return [_c('JustificationActions',{attrs:{"justification":_vm.justification,"validations":{
        canReview: _vm.canReview,
        canManagerReview: _vm.canManagerReview,
        canClose: _vm.canClose,
        canOpen: _vm.canOpen
      }},on:{"review":function($event){_vm.openManageJustification = true},"close":_vm.confirmCloseJustification,"draft":_vm.confirmOpenJustification,"manager-review":_vm.confirmOpenToManagerJustification}})]},proxy:true},{key:"main-sidebar",fn:function(){return [_c('SidebarJustification',{attrs:{"section":_vm.section,"manage":true}})]},proxy:true},{key:"main-content",fn:function(){return [_c('PanelJustification',{attrs:{"justification":_vm.justification,"absence":_vm.absence,"users":_vm.users,"comments":_vm.comments,"loading":_vm.loading,"saving-data":_vm.savingData,"validations":{
        canOpen: _vm.canOpen,
        canComment: _vm.canComment,
        canReview: _vm.canReview,
        canManagerReview: _vm.canManagerReview,
        canClose: _vm.canClose,
        canEdit: false,
        canSubmit: false,
        canDelete: false
      }},on:{"update:justification":function($event){_vm.justification=$event},"update:absence":function($event){_vm.absence=$event},"update:users":function($event){_vm.users=$event},"update:comments":function($event){_vm.comments=$event}}})]},proxy:true},{key:"modals",fn:function(){return [_c('fw-modal',{attrs:{"active":_vm.openManageJustification}},[_c('ManageJustification',{attrs:{"loading":_vm.loading,"justification":_vm.justification},on:{"reject":_vm.confirmRejectJustification,"approve":_vm.confirmApproveJustification,"close":function($event){_vm.openManageJustification = false}}})],1)]},proxy:true},{key:"debug",fn:function(){return [_c('fw-panel-info',{attrs:{"label":"Data (raw)"}},[_c('json-viewer',{attrs:{"value":{ absence: _vm.absence, justifications: _vm.justifications }}})],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }