<template>
  <fw-layout :loading="loading" management>
    <template v-if="justification.status" #header-nav>
      <fw-tag size="sm" :type="statusTagType" custom-class="px-3">
        <span v-if="!['draft', 'closed'].includes(justification.status)" class="font-regular opacity-70 mr-1">{{
          $t('status.label')
        }}</span>
        {{ $t(`status.${justification.status}`) }}
      </fw-tag>
    </template>

    <template #header-toolbar>
      <JustificationActions
        :justification="justification"
        :validations="{
          canReview: canReview,
          canManagerReview: canManagerReview,
          canClose: canClose,
          canOpen: canOpen
        }"
        @review="openManageJustification = true"
        @close="confirmCloseJustification"
        @draft="confirmOpenJustification"
        @manager-review="confirmOpenToManagerJustification"
      />
    </template>

    <template #main-sidebar>
      <SidebarJustification :section="section" :manage="true" />
    </template>

    <template #main-content>
      <PanelJustification
        :justification.sync="justification"
        :absence.sync="absence"
        :users.sync="users"
        :comments.sync="comments"
        :loading="loading"
        :saving-data="savingData"
        :validations="{
          canOpen: canOpen,
          canComment: canComment,
          canReview: canReview,
          canManagerReview: canManagerReview,
          canClose: canClose,
          canEdit: false,
          canSubmit: false,
          canDelete: false
        }"
      />
    </template>

    <template #modals>
      <fw-modal :active="openManageJustification">
        <ManageJustification
          :loading="loading"
          :justification="justification"
          @reject="confirmRejectJustification"
          @approve="confirmApproveJustification"
          @close="openManageJustification = false"
        ></ManageJustification>
      </fw-modal>
    </template>

    <template #debug>
      <fw-panel-info label="Data (raw)">
        <json-viewer :value="{ absence, justifications }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import SidebarJustification from '@/components/sidebars/SidebarJustification'
import JustificationActions from '@/components/header/JustificationActions'
import ManageJustification from '@/components/modals/ManageJustification'
import PanelJustification from '@/components/panels/PanelJustification'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    SidebarJustification,
    JustificationActions,
    ManageJustification,
    PanelJustification
  },

  data() {
    return {
      loading: true,
      absence: {},
      files: [],
      justification: {
        motive: null,
        dates: [
          {
            startAt: null,
            endAt: null
          }
        ],
        startTime: null,
        endTime: null,
        relativeType: null,
        dateOfDeath: null,
        option: null,
        optionDescription: null,
        availableHours: null,
        hoursToUse: null,
        description: null,
        files: []
      },
      savingData: false,
      canOpen: false,
      canComment: false,
      canReview: false,
      canManagerReview: false,
      canClose: false,
      openManageJustification: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    justificationKey() {
      return this.$route.params.key
    },

    section() {
      return this.$route.params.section
    },

    isMapManager() {
      return this.justification.manager == this.user.key
    },

    isMapSubManager() {
      return this.justification.sub_managers.includes(this.user.key)
    },

    statusTagType() {
      let type = 'medium'
      if (this.justification.status == 'draft') {
        type = 'orange'
      } else if (this.justification.approved == true) {
        type = 'primary'
      } else if (this.justification.approved == false) {
        type = 'light-danger'
      }
      return type
    }
  },

  created() {
    this.maxDate = new Date()
    this.getJustificationData()
  },

  methods: {
    setJustificationData(justification) {
      console.log('setJustificationData :>> ', justification)
      if (!justification) return

      const tmpDates = []
      let startTime = null
      let endTime = null
      if (justification.dates && justification.dates.length) {
        justification.dates.forEach(date => {
          tmpDates.push({
            start_at: date.start_at ? new Date(date.start_at) : null,
            end_at: date.end_at ? new Date(date.end_at) : null
          })
        })

        if (justification.start_time) {
          startTime = new Date(`${justification.dates[0].start_at} ${justification.start_time}`)
        }

        if (justification.end_time) {
          endTime = new Date(`${justification.dates[0].start_at} ${justification.end_time}`)
        }
      } else {
        startTime = justification.start_time ? Dates.from(justification.start_time).toDate() : null
        endTime = justification.end_time ? Dates.from(justification.end_time).toDate() : null
      }

      if (!tmpDates.length) {
        tmpDates.push({
          start_at: new Date(),
          end_at: new Date()
        })
      }

      const tmpJ = {
        key: this.justificationKey,
        dates: tmpDates,
        start_time: startTime,
        end_time: endTime,
        death_date: justification.death_date ? new Date(justification.death_date) : null
      }
      this.justification = Object.assign({}, justification, tmpJ)
    },

    setValidationData(validations) {
      if (!validations) return
      this.canComment = validations.can_comment
      this.canOpen = validations.can_open
      this.canClose = validations.can_close
      this.canReview = validations.can_review
      this.canManagerReview = validations.can_manager_review
    },

    async getJustificationData() {
      this.loading = true

      try {
        const response = await this.api.getJustification(this.justificationKey, {
          with_validations: true
        })
        console.log('getJustification :>> ', response)
        this.absence = response.absence
        this.users = response.users
        this.comments = response.comments
        this.setJustificationData(response.justification)
        this.setValidationData(response.validations)
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('error :>> ', errorKey, error)
        if (errorKey) {
          if (errorKey === 'NotFound') return this.$router.push({ name: 'error' })
          else if (errorKey === 'Forbidden' || errorKey === 'Unauthorized')
            return this.$router.push({ name: 'forbidden' })
        }
      }

      await utils.sleep(250)
      this.loading = false
    },

    async confirmOpenToManagerJustification() {
      if (!this.canClose) return

      this.$buefy.dialog.confirm({
        confirmText: 'Abrir',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Abrir justificação',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>abrir</strong> esta justificação?
        Ao abrir, volta para o responsável.</div>`,
        onConfirm: () => {
          this.updateStatus({ status: 'reviewed' })
        }
      })
    },
    async confirmOpenJustification() {
      if (!this.canClose) return

      this.$buefy.dialog.confirm({
        confirmText: 'Abrir',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Abrir justificação',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>abrir</strong> esta justificação?
        Ao abrir, volta para o trabalhador.</div>`,
        onConfirm: () => {
          this.updateStatus({ status: 'draft' })
        }
      })
    },

    async confirmCloseJustification() {
      if (!this.canClose) return

      this.$buefy.dialog.confirm({
        confirmText: 'Fechar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Fechar justificação',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>fechar</strong> esta justificação?
        Ao fechar, está a validar a justificação.</div>`,
        onConfirm: () => {
          this.updateStatus({ status: 'closed' })
        }
      })
    },

    async confirmApproveJustification(data) {
      console.log('confirmApproveJustification')
      if (!this.canReview && !this.canManagerReview) return

      this.$buefy.dialog.confirm({
        confirmText: 'Aprovar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Aprovar justificação',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>aprovar</strong> esta justificação?</div>`,
        onConfirm: () => {
          this.updateStatus(data)
        }
      })
    },

    async confirmRejectJustification(data) {
      console.log('confirmRejectJustification')
      if (!this.canReview && !this.canManagerReview) return

      this.$buefy.dialog.confirm({
        confirmText: 'Rejeitar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Rejeitar justificação',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>rejeitar</strong> esta justificação?</div>`,
        onConfirm: () => {
          this.updateStatus(data)
        }
      })
    },

    async updateStatus(data) {
      this.savingData = true

      const payload = {
        status: data.status
      }

      if (data.action) payload['action'] = data.action
      if (data.comment) payload['comment'] = data.comment
      if (data.type) payload['absence_communication'] = data.type
      if (data.date) {
        payload['absence_communication_date'] = Dates.buildCore(data.date).format('YYYY-MM-DD')
      }

      try {
        const response = await this.api.updateJustificationStatus(this.justificationKey, payload)
        console.log('response :>> ', response)
        this.setJustificationData(response.justification)
        this.setValidationData(response.validations)
      } catch (error) {
        console.log('error :>> ', error)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao tentar guardar',
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false
        })
      }

      this.savingData = false
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "personalDetails": "Dados pessoais",
    "absenceJustification": "Justificação de ausência",
    "startDate": "Data de início",
    "endDate": "Data de fim",
    "startTime": "Hora de início",
    "endTime": "Hora de fim",
    "absenceMotive": "Motivo da falta",
    "uploadFiles": "Carregar ficheiros",
    "hasErrors": "Existem erros no formulário",
    "cancel": "Cancelar",
    "reopen": "Reabrir",
    "submit": "Submeter",
    "save": "Guardar",
    "motives": {
      "wedding": "Casamento",
      "deathOfRelativeOrKin": "Falecimento de familiar ou afins",
      "nonAttributableFacts": "Factos não imputáveis ao/à trabalhador/a",
      "medical": "Pela necessidade de tratamento ambulatório, realização de consultas médicas ou exames complementares de diagnóstico",
      "educationalSituationOfMinor": "Deslocação à escola para se inteirar da situação educativa de filho/a menor",
      "firstDayOfSchool": "Acompanhamento de filho/a menor de 12 anos no primeiro dia de escola",
      "bloodDonationFirstAid": "Doação de sangue e socorrismo",
      "procedure": "Necessidade de submissão a métodos de seleção em procedimento concursal",
      "electedWorkers": "Trabalhadores/as eleitos/as para estruturas de representação coletiva",
      "publicOfficeCandidate": "Candidatos/as a eleições para cargos públicos durante o período legal da campanha eleitoral",
      "prenatalConsultations": "Dispensa para consultas pré-natais",
      "timeCreditUse": "Gozo de crédito de horas do mês anterior",
      "externalService": "Serviço externo no(s) dia(s)",
      "occasionalInterruptions": "Interrupções ocasionais",
      "annualCredit": "Crédito Anual",
      "other": "Outras faltas previstas na Lei"
    },
    "status": {
      "label": "Estado",
      "draft": "Rascunho",
      "submitted": "Submetido",
      "approved": "Aprovado",
      "denied": "Rejeitado",
      "reviewed": "Revisto",
      "manager_reviewed": "Reviewed by manager",
      "closed": "Fechado"
    }
  },
  "en": {
    "personalDetails": "Personal details",
    "absenceJustification": "Absence justification",
    "startDate": "Start date",
    "endDate": "End date",
    "startTime": "Start time",
    "endTime": "End time",
    "absenceMotive": "Reason for absence",
    "uploadFiles": "Upload files",
    "hasErrors": "There are errors in the form",
    "cancel": "Cancel",
    "reopen": "Reopen",
    "submit": "Submit",
    "save": "Save",
    "motives": {
      "wedding": "Wedding",
      "deathOfRelativeOrKin": "Death of relative or kin",
      "nonAttributableFacts": "Factors not attributable to the employee",
      "medical": "For the need for outpatient treatment, medical appointments, or complementary diagnostic tests",
      "educationalSituationOfMinor": "Trip to the school to find out about the educational situation of a minor child",
      "firstDayOfSchool": "Accompaniment of a child under 12 on the first day of school",
      "bloodDonationFirstAid": "Blood donation and first aid",
      "procedure": "Need to submit to selection methods in a competitive bidding procedure",
      "electedWorkers": "Workers elected to collective representation structures",
      "publicOfficeCandidate": "Candidates for public office during the legal period of the election campaign",
      "prenatalConsultations": "Exemption for prenatal consultations",
      "timeCreditUse": "Use of time credit from the previous month",
      "externalService": "External service on the day(s)",
      "occasionalInterruptions": "Occasional interruptions",
      "annualCredit": "Annual Credit",
      "other": "Other absences foreseen in the law"
    },
    "status": {
      "label": "Status",
      "draft": "Draft",
      "submitted": "Submitted",
      "approved": "Approved",
      "denied": "Rejected",
      "reviewed": "Reviewd",
      "manager_reviewed": "Reviewed by manager",
      "closed": "Closed"
    }
  }
}
</i18n>

<style lang="postcss">
.update-modal-uploader .file-uploads {
  @apply w-full;
}

.fw-select .select {
  @apply rounded shadow w-full;
}
.fw-select .select select {
  @apply w-full;
}
.fw-select .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select:hover .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}

.fw-select:hover .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}
.fw-select:hover .select select {
  @apply border border-gray-200;
}
.fw-select .select select {
  @apply border border-gray-200;
}
.fw-select .select:not(.is-multiple) {
  height: 2.5rem;
}
.fw-select .select select {
  height: 2.5rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.fw-select .select select:focus {
  border-color: transparent;
}

.dropdown-trigger .control.has-icons-left .icon {
  top: 1px;
  height: 2.5em;
  width: 2.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
</style>
